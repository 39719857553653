@use "../../sass" as *;

header {
  width: 100%;
  height: 70px;
  background-color: $primary-clr;

  nav {
    height: 100%;
    @include center();
    
    ul {
      @include center();
      gap: 16px;

      a:hover {
        color: $secondary-clr;
      }
    }
  }
}
